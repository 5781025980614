let parseToShortFloat = (val) =>{
    if (val && parseFloat(val)){
        return parseFloat(val).toFixed(2)
    }
    return 0;
}
let positiveNumber = (event) =>{
    let _keys = ['-', '+', 'e'];
    return  _keys.includes(event.key) ? event.preventDefault() : null
}

export default {parseToShortFloat, positiveNumber};